<template>
  <v-container v-if="dataLoaded">
    <v-row>
      <v-col
        cols="12"
        class="ma-0 mb-3 py-0"
      >
        <v-card class="pa-0">
          <v-breadcrumbs :items="breadcrumbItems">
            <template v-slot:divider>
              <v-icon color="primary">
                mdi-chevron-right
              </v-icon>
            </template>
          </v-breadcrumbs>
        </v-card>
      </v-col>
    </v-row>
 
    <v-row 
      v-if="isEdit"
      class="my-1 d-flex align-center"
    >
      <v-col cols="8" class="d-flex align-center" style="gap: 1em;">
        <v-icon large>
          mdi-billboard
        </v-icon>
        <span v-if="isEdit" class="text-h2"> {{screenGroup.mediaOwner.friendlyName}} - {{screenGroup.name }}</span>
      </v-col>
      <v-spacer />
      <v-speed-dial
        direction="left"
        :open-on-hover="true"
        v-if="screen != null"
      >
        <template v-slot:activator>
          <v-btn
            small
            color="primary"
            fab
            class="mr-3"
          >
            <v-icon medium>
              mdi-cog
            </v-icon>
          </v-btn>
        </template>

        <!-- Media Type
        <v-btn
          rounded
          color="primary"
          @click="mediaTypeDialog = true"
        >
          <v-icon left>
            mdi-plus
          </v-icon>Media Type
        </v-btn> -->

        <!-- Screen Scope -->
        <v-btn
          v-if="$store.state.Permissions.screenScopeEdit"
          @click="showSetupSchedule = true"
          rounded
          color="secondary"
        >
          <v-icon left>
            mdi-clock-outline
          </v-icon>Screen Scope
        </v-btn>
        
        <!-- Branding Badge
        <v-btn
          rounded
          color="tertiary"
          @click="brandingBadgeDialog = true"
        >
          <v-icon left>
            mdi-plus
          </v-icon>Branding Badge
        </v-btn> -->

        <!-- Field Notes -->
        <v-btn
          rounded
          color="fourth"
          @click="openFieldNotes()"
        >
          <v-icon left>
            mdi-message
          </v-icon>Field Notes
        </v-btn>

        <v-btn
          fab
          small
          color="red"
          @click="deleteFormatDialog = true"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-row>

        <v-form
          ref="form"
          v-model="valid"
        >
            <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title
                  class="white--text primary py-2"
                >
                  {{ isEdit ? 'Specifications' : 'Add Screen' }}
                </v-card-title>
                <!--Delivery types tabs Edit Version-->
                <v-row v-if="isEdit">
                  <v-col cols="12">
                    <v-tabs v-model="tab">
                      <v-tab
                        v-for="screen in screenGroup.screens" 
                        :key="screen.id + '_' + screen.deliveryType.name" 
                        @click=" screenByDeliveryType(screen)"
                      >
                      {{ screen.deliveryType.name }}
                      </v-tab>
                    </v-tabs>
                  </v-col>
                </v-row>
                <!-- Disclaimer if screen does not exist-->
                <v-row v-if="isEdit && !isScreenPresent">
                  <v-col cols="12">
                    <h4 class="mx-5 primary--text">{{ activeDeliveryType.deliveryType.name }} screen does not exist. Fill in the relevant information to add screen</h4>
                  </v-col>
                </v-row>
                <!--Delivery types tabs Add Version-->
                <v-row v-if="!isEdit">
                  <v-col cols="12">
                    <v-tabs v-model="tab">
                      <v-tab
                        v-for="screen in screenGroup.screens" 
                        :key="screen.id + '_' + screen.name" 
                        @click=" activeDeliveryType = screen"
                      >
                      {{ screen.name != null ? screen.name : 'null' }}
                      </v-tab>
                    </v-tabs>
                  </v-col>
                </v-row>
                <v-row class="pa-4 pt-4">
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="name"
                      outlined
                      label="Name*"
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="selectedMO"
                      outlined
                      :items="mos"
                      name="selectedMO"
                      label="Media Owner*"
                      :rules="requiredRule"
                      item-text="friendlyName"
                      item-value="friendlyName"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="category"
                      outlined
                      name="category"
                      label="Category"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="webLink"
                      outlined
                      name="linktoformat"
                      label="Link To Format"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="fileNamePrefix"
                      outlined
                      name="filenameprefix"
                      label="File Name Convention"
                    />
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      v-model="width"
                      outlined
                      type="number"
                      label="Width*"
                      name="width"
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="height"
                      outlined
                      label="Height*"
                      name="height"
                      :rules="requiredRule"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="offsetX"
                      outlined
                      label="Offset X"
                      name="offsetX"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="offsetY"
                      outlined
                      label="Offset Y"
                      name="offsetY"
                      type="number"
                    />
                  </v-col>

                  <v-col cols="6">
                    <v-select
                      v-model="selectedRotation"
                      outlined
                      label="Rotation"
                      name="rotation"
                      :items="rotationTypes"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="selectedMotion"
                      outlined
                      label="Motion Support*"
                      name="motionSupport"
                      :rules="requiredRule"
                      :items="motionTypes"
                      item-text="name"
                      :item-value="'name'"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="slotBreakSeconds"
                      outlined
                      name="slotBreakSeconds"
                      label="Slot Break (Seconds)*"
                      :rules="requiredRule"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="slotDurationSeconds"
                      outlined
                      name="slotDurationSeconds"
                      label="Slot Duration (Seconds)*"
                      :rules="requiredRule"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="maxFileSize"
                      outlined
                      name="maxFileSize"
                      label="Max file size (KB)"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="isResponsive"
                      outlined
                      name="isResponsive"
                      label="Responsive"
                      :items="[true,false]"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="publishType"
                      outlined
                      label="Publish Type"
                      name="publishType"
                      :items="publishTypes"
                      item-text="name"
                      :item-value="'name'"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="machineType"
                      outlined
                      label="Machine Type"
                      name="machineType"
                      :items="machineOptions"
                      item-text="name"
                      :item-value="'name'"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="operatingSystem"
                      outlined
                      name="operatingSystem"
                      label="Operating System"
                      :items="operatingSystemOptions"
                      item-text="name"
                      :item-value="'name'"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="syncFrequencySeconds"
                      outlined
                      name="syncFrequencySeconds"
                      label="Sync Frequency (Seconds)"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="playerSoftware"
                      outlined
                      label="Player Software"
                      name="playerSoftware"
                      :items="playerSoftwares"
                      item-text="name"
                      :item-value="'name'"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    class="text-left mt-2"
                    v-if="isEdit"
                  >
                    <v-btn
                      rounded
                      class="white--text secondary"
                      @click="showAddParentScreenDialog = true"
                    >
                      MAKE CHILD SCREEN
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    class="text-right my-n2"
                  >
                    <v-btn
                      class="white--text primary"
                      @click="(isEdit && isScreenPresent) ? affectedScreen('edit') : addScreen()"
                    >
                      SAVE
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
    <!-- Screen Scope -->
    <SetupSchedule
      v-if="showSetupSchedule"
      :screen="screenGroup.screens.find((item) => item.deliveryType.shortName == screen.deliveryType.shortName).screen"
      :deliveryType="screenGroup.screens.find((item) => item.deliveryType.shortName == screen.deliveryType.shortName).deliveryType"
      :screenGroup="screenGroup"
      :showDialog="showSetupSchedule"
      @updateDialog="getScreenData()"
      @closeDialog="showSetupSchedule = false"
    />

    <!-- Make Child Screen -->
    <v-dialog
      v-if="isEdit"
      v-model="showAddParentScreenDialog"
      width="700"
    >
      <v-card>
        <v-card-title class="white--text primary">
          Make Child Screen
        </v-card-title>
        <v-row class="pa-4">
          <v-col
            v-if="addedParentScreen.length < 1"
            cols="12"
            md="10"
            class="py-0 my-0"
          >
            <v-autocomplete
              v-model="makeChildOf"
              outlined
              auto-select-first
              clearable
              label="Make Child Of"
              :items="formats"
              item-text="name"
              return-object
              color="primary"
            >
              <template
                slot="selection"
                slot-scope="data"
              >
                <!-- HTML that describe how select should render selected items -->
                {{ data.item.name }} - {{ data.item.mediaOwner.friendlyName }}
              </template>
              <template
                slot="item"
                slot-scope="data"
              >
                <!-- HTML that describe how select should render items when the select is open -->
                {{ data.item.name }} - {{ data.item.mediaOwner.friendlyName }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            v-if="addedParentScreen.length < 1"
            cols="12"
            md="2"
            class="py-0 my-0 text-right"
          >
            <v-btn
              color="primary"
              @click="addedParentScreen.push(makeChildOf)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-data-table
              v-if="addedParentScreen.length > 0"
              :headers="headers"
              :items="addedParentScreen"
              class="elevation-1"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn
                  icon
                  color="red"
                  class="mr-2"
                >
                  <v-icon
                    color="red"
                    @click="addedParentScreen.splice(item)"
                  >
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
            <span
              v-else
              class="text-body-1"
            >This format is not a child screen.</span>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- Media Types -->
    <v-dialog
      v-if="isEdit"
      v-model="mediaTypeDialog"
      width="500"
    >
      <v-card>
        <v-card-title
          class="white--text primary"
        >
          Supported Media Types
        </v-card-title>
        <v-row class="pa-4">
          <v-col
            cols="12"
            md="9"
            class="py-3 my-0"
          >
            <v-autocomplete
              v-model="selectedMediaType"
              outlined
              auto-select-first
              clearable
              label="Available Media Types"
              :items="mediaTypes"
              item-text="shortName"
              return-object
              color="primary"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="text-right"
            style="margin-top:7px"
          >
            <v-btn
              color="primary"
              @click="addSupportedMedia(selectedMediaType)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            class="py-0 my-0"
          >
            <v-data-table
              :items="supportedMediaData"
              :headers="mediaTypesHeaders"
              :footer-props="{ 'items-per-page-options': [5] }"
            >
              <template
                v-if="$store.state.Permissions.SupportedMediaDelete"
                v-slot:item.actions="{ item }"
              >
                <v-btn
                  icon
                  color="primary"
                  class="mr-2"
                  @click="removeSupportedMedia(item)"
                >
                  <v-icon color="primary">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- Field Notes -->
    <v-dialog
      v-if="isEdit"
      v-model="fieldNoteDialog"
      width="600"
    >
      <field-notes :screen="activeDeliveryType" :fieldnotes="fieldnotes"/>
    </v-dialog>

    <!-- Branding Badge -->
    <v-dialog
      v-if="isEdit"
      v-model="brandingBadgeDialog"
      width="800"
    >
      <v-card>
        <v-card-title
          class="white--text primary"
        >
          Branding Badge
        </v-card-title>
        <v-row class="pa-4">
          <v-col
            v-if="addedBrandingBadgeScreens.length < 1"
            cols="12"
            md="10"
            class="py-3 my-0"
          >
            <v-autocomplete
              v-model="selectedFormat"
              outlined
              auto-select-first
              clearable
              label="Available Formats"
              :items="formats"
              item-text="name"
              return-object
              color="primary"
            >
              <template
                slot="selection"
                slot-scope="data"
              >
                <!-- HTML that describe how select should render selected items -->
                {{ data.item.name }} - {{ data.item.mediaOwner.friendlyName }}
              </template>
              <template
                slot="item"
                slot-scope="data"
              >
                <!-- HTML that describe how select should render items when the select is open -->
                {{ data.item.name }} - {{ data.item.mediaOwner.friendlyName }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            v-if="addedBrandingBadgeScreens.length < 1"
            cols="12"
            md="2"
            class="py-0 my-0 text-right"
          >
            

            <v-btn
              color="primary"
              
              @click="addedBrandingBadgeScreens.push(screenGroup.screens.find(screen => screen.deliveryType.id === activeDeliveryType.deliveryType.id))"
              
              style="margin-top: 20px;"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-data-table
              v-if="addedBrandingBadgeScreens.length > 0"
              :headers="headers"
              :items="addedBrandingBadgeScreens"
              class="elevation-1"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn
                  icon
                  color="red"
                  class="mr-2"
                >
                  <v-icon
                    color="red"
                    @click="addedBrandingBadgeScreens.splice(item)"
                  >
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
            <span
              v-else
              class="text-body-1"
            >This format does not have a branding badge screen.</span>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- Delete Screen -->
    <v-dialog
      v-if="isEdit"
      v-model="deleteFormatDialog"
      width="600"
    >
      <v-card class="pa-4">
        Are you sure you want to delete this screen?<br/>Note: This will only delete the {{activeDeliveryType.deliveryType.name}} screen.
        <v-card-actions>
          <v-col
            cols="12"
            class="text-right"
          >
            <v-btn
              class="mx-2"
              color="primary"
              @click="affectedScreen('delete')"
            >
              Confirm
            </v-btn>
            <v-btn
              color="red"
              class="mx-2"
              @click="deleteFormatDialog = false"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Campaign Burst Screens Usage -->
    <v-dialog
      v-if="isEdit"
      v-model="showAffectedScreenDialog"
      width="800"
    >
      <v-card-title
        class="white--text primary py-2"
      >
        Campaigns Affected
      </v-card-title>
      <v-card class="pa-4">
        The following active campaigns are affected by this change:
        <v-row class="pa-4">
          <v-col>
            <v-data-table
            :headers="affectedCampaignHeaders"
            :items="affectedCampaignBurstScreens"
          >
          </v-data-table>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col>
            Are you sure you want to continue?
          </v-col>
        </v-row>
        <v-card-actions>
          <v-col
            cols="12"
            class="text-right"
          >
            <v-btn
              class="mx-2"
              color="primary"
              @click="decideEditAction()"
            >
              Confirm
            </v-btn>
            <v-btn
              color="red"
              class="mx-2"
              @click="showAffectedScreenDialog = false"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import ScreenController from '@/services/controllers/Screen'
  import CampaignController from '@/services/controllers/Campaign'
  import MediaTypeController from '@/services/controllers/MediaType'
  import SetupSchedule from '@/components/screen/SetupSchedule'
  import FieldNotes from '@/components/formats/FieldNotes'

  export default {
    components: {
      FieldNotes,
      SetupSchedule
    },

    data: () => ({
      // Tabs
      tab: 0,
      // Initial check
      dataLoaded: false,
      showSetupSchedule: false,
      campaignName: null,
      routeFrom: null,
      routeNext: null,
      routeReplace: {},
      breadcrumbItems: [],
      formats: [],
      isResponsive: false,
      editedNote: null,
      valid: false,
      mediaTypeDialog: false,
      deleteFormatDialog: false,
      brandingBadgeDialog: false,
      makeChildOf: false,
      paginationData: { rowsPerPage: 5 },
      addedBrandingBadgeScreens: [],
      requiredRule: [(v) => !!v || 'Field is required'],
      screen: null,
      // Screen Info
      name: null,
      selectedMO: null,
      showAddBrandingBadgeScreenDialog: false,
      fieldNoteDialog: false,
      showAddParentScreenDialog: false,
      category: null,
      webLink: null,
      offsetY: 0,
      offsetX: 0,
      selectedMediaType: null,
      height: null,
      width: null,
      selectedRotation: 0,
      selectedMotion: null,
      selectedFormat: null,
      publishType: null,
      machineType: null,
      maxFileSize: 0,
      motionTypes: [],
      publishTypes: [],
      addedParentScreen: [],
      operatingSystemOptions: [],
      playerSoftwares: [],
      rotationTypes: [-90, 0, 90],
      mos: [],
      mediaTypesHeaders: [
        {
          text: 'Type',
          align: 'start',
          sortable: true,
          value: 'mediaType.shortName',
        },
        {
          text: 'Actions',
          align: 'start',
          sortable: true,
          value: 'actions',
        },
      ],
      availableTypesHeaders: [
        {
          text: 'Type',
          align: 'start',
          sortable: true,
          value: 'shortName',
        },
        {
          text: 'Actions',
          align: 'start',
          sortable: true,
          value: 'actions',
        },
      ],
      headers: [
        {
          text: 'Media Owner',
          align: 'start',
          sortable: true,
          value: 'mediaOwner.name',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Category',
          align: 'start',
          sortable: true,
          value: 'category',
        },
        {
          text: 'Height',
          align: 'start',
          sortable: true,
          value: 'height',
        },
        {
          text: 'Width',
          align: 'start',
          sortable: true,
          value: 'width',
        },
        {
          text: 'Orientation',
          align: 'start',
          sortable: true,
          value: 'orientation',
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
        },
      ],
      orientations: ['portrait', 'landscape'],
      frequency: ['Network', 'Landmark'],
      supportedMediaData: [],
      mediaTypes: [],
      osOptions: [],
      screenChildScreens: [],
      machineOptions: [],
      slotDurationSeconds: null,
      slotBreakSeconds: null,
      operatingSystem: null,
      playerSoftware: null,
      fileNamePrefix: null,
      syncFrequencySeconds: 0,
      screenEvents: null,
      screenGroup: {},
      screenGroupDub: {},
      activeDeliveryType: null,
      isScreenPresent: false,
      isEdit: false,
      // FieldNotes
      fieldNoteDialog: false,
      fieldnotes: [],
      // AffectedScreens (usage)
      affectedCampaignBurstScreens: [],
      showAffectedScreenDialog: false,
      editAction: null,
      affectedCampaignHeaders: [
          {
            text: 'Campaign',
            align: 'start',
            sortable: true,
            value: 'campaignBurst.campaign.name',
          },
          {
            text: 'Burst',
            align: 'start',
            sortable: true,
            value: 'campaignBurst.name',
          },
          {
            text: 'Media Owner',
            align: 'start',
            sortable: true,
            value: 'screen.mediaOwner.friendlyName',
          },
          {
            text: 'Screen',
            align: 'start',
            sortable: true,
            value: 'screen.name',
          },
        ],
    }),
    beforeMount() {
      this.isEdit = (this.$route.query.id) ? true : ((this.$route.query.screenId) ? true : false)
    },
    async created () {
      await this.getRouteReplace()
      await this.getScreenData()

      await this.sanitizeBreadCrumb()
      await this.checkForTabSelection()
      //await this.getDeliveryTypes()
      //Get Delivery Types
      this.dataLoaded = true

    },
    // Provide method updateFieldNote
    provide: function () {
        return {
          updateFieldNote: this.updateFieldNote,
          deleteFieldNote: this.deleteFieldNote,
          setScreenEvents: this.setScreenEvents
        }
    },
    computed: {
      checkNaviagtion(){
        if(this.routeFrom === 'artwork') {
          return this.$route.query.screenId
        } else {
          return this.$route.query.id
        }
      }
    },
    methods: {
      // Delete screenevent from this.screengroup
      setScreenEvents(screenEvents){
        this.screenGroup.screenEvents = [...screenEvents]
        this.$nextTick()
      },
      // Function that deletes the fieldnote
      deleteFieldNote(fieldNoteId){
        this.fieldnotes = this.fieldnotes.filter((item) => item.id !== fieldNoteId)
        this.$nextTick()
      },
      // Function that updates the fieldnotes
      updateFieldNote(updatedNote){
        // Update fieldnotes with the updated note using splice
        this.fieldnotes.splice(this.fieldnotes.findIndex(x => x.id === updatedNote.id), 1, updatedNote)
        this.$nextTick()
      },
      async openFieldNotes(){
        this.fieldNoteDialog = true
        await ScreenController.getFieldNotesPerScreen(this.screen.screen.id)
          .then((res) => {
            // Sort by Date
            res.data.sort(function (a, b) {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return new Date(b.dateUpdated) - new Date(a.dateUpdated)
            })
            this.fieldnotes = res.data
            this.$nextTick()
          })
      },
      // Function that verifies the tabs for correctness
      checkForTabSelection() {
        if(this.isEdit) {
          this.tab = this.activeDeliveryType.deliveryType.id-1
        }
      },
      screenByDeliveryType(reScreen){ 
          this.activeDeliveryType = reScreen
          const screen = this.screenGroup.screens.find(x => x.deliveryType.shortName == reScreen.deliveryType.shortName).screen
          if(screen != null)        
          {
            this.isScreenPresent = true
            this.category = screen.category
            this.offsetX = screen.offsetX
            this.offsetY = screen.offsetY
            this.width = screen.width
            this.height = screen.height
            this.selectedRotation = screen.rotation
            this.selectedMotion = screen.motionSupport
            this.fileNamePrefix = screen.filenameConventionPrefix
            this.slotDurationSeconds = screen.slotDurationSeconds
            this.slotBreakSeconds = screen.slotBreakSeconds
            this.maxFileSize = screen.maxFileSizeKB
            this.webLink = screen.webLink
            this.screenEvents = screen.screenEvents

            this.screen = { ...this.screenGroup.screens.find(x => x.deliveryType.shortName == reScreen.deliveryType.shortName) }
            if (screen.numberOfScreens > 0) {
              this.getChildScreens()
            }

            if (screen.dynamicScreen) {
              if (screen.dynamicScreen.publishType) {
                this.publishType = screen.dynamicScreen.publishType.name
              }
              if (screen.dynamicScreen.machineType) {
                this.machineType = screen.dynamicScreen.machineType.name
              }
              if (screen.dynamicScreen.playerSoftware) {
                this.playerSoftware = screen.dynamicScreen.playerSoftware.name
              }

              this.syncFrequencySeconds =
                screen.dynamicScreen.syncFrequencySeconds
              if (screen.dynamicScreen.operatingSystem) {
                this.operatingSystem = screen.dynamicScreen.operatingSystem.name
              }
              this.isResponsive = screen.dynamicScreen.isResponsive
            }

            if (screen.brandingBadgeScreen !== null) {
              this.addedBrandingBadgeScreens.push(screen.brandingBadgeScreen)
            }

            if (screen.parentScreen !== null) {
              this.addedParentScreen.push(screen.parentScreen)
            }
          }
          else
          {
            this.isScreenPresent = false
            this.screen = null
            this.category = ''
            this.offsetX = 0
            this.offsetY = 0
            this.width = 0
            this.height = 0
            this.selectedRotation = 0
            this.selectedMotion = ''
            this.fileNamePrefix = ''
            this.slotDurationSeconds = 0
            this.slotBreakSeconds = 0
            this.maxFileSize = 0
            this.webLink = ''
            this.screenEvents = []

            this.publishType = ''
            this.machineType = ''
            this.playerSoftware = ''
            this.syncFrequencySeconds = 0
            this.operatingSystem = ''
            this.isResponsive = false
            this.addedBrandingBadgeScreens = []
            this.addedParentScreen = []
          }
      },

      async getScreenData() {
        ScreenController.allScreens().then(res => {
          this.formats = res.data
        })

        MediaTypeController.getMediaTypes().then(res => {
          this.mediaTypes = res.data
        })

        ScreenController.getMOList().then(res => {
          this.mos = res.data
        })
        if(this.isEdit && this.routeFrom !== 'artwork') { 
          await ScreenController.getSupportedMedia(this.$route.query.id).then(res => {
            this.supportedMediaData = res.data
          })
          await ScreenController.getScreenGroup(this.$route.query.id).then( async (res) => {
            this.screenGroup = res.data
            const firstActive = this.screenGroup.screens.find(x => x.screen != null)
            this.screenByDeliveryType(firstActive)
            this.name = this.screenGroup.name
            this.selectedMO = this.screenGroup.mediaOwner.friendlyName
          })
        } else if (this.isEdit && this.routeFrom === 'artwork') {
          await ScreenController.getScreenGroupByScreen(this.$route.query.screenId).then((res) => {
            this.screenGroup = res.data.screenGroup
            const deliveryType = res.data.deliveryType
            // Find firstActive where deliveryType in screen is equal to deliveryType in screenGroup
            const firstActive = this.screenGroup.screens.find(x => x.deliveryType.id == deliveryType.id)
            this.name = this.screenGroup.name
            this.selectedMO = this.screenGroup.mediaOwner.friendlyName
            this.screenByDeliveryType(firstActive)
          })
        } else {
          await CampaignController.deliveryTypes().then((res) => {
            Object.assign(this.screenGroupDub, {screens: res.data})
            Object.assign(this.screenGroup, {screens: res.data})
            // Set the first delivery type as active
            this.activeDeliveryType = this.screenGroup.screens[0]
          })
        }

        ScreenController.getPublishTypes().then(res => {
          this.publishTypes = res.data
        })

        ScreenController.getMotionSupport().then(res => {
          this.motionTypes = res.data
        })

        ScreenController.getMachineTypes().then(res => {
          this.machineOptions = res.data
        })

        ScreenController.getOSTypes().then(res => {
          this.operatingSystemOptions = res.data
        })

        ScreenController.getPlayerSoftware().then(res => {
          this.playerSoftwares = res.data
        })
      },

      async getRouteReplace() {
        // Replace router params
        this.routeFrom = this.$route.query.from
        this.routeNext = this.$route.query.next

        if(this.routeFrom) {
          this.routeReplace = {}
          const row = this.routeNext.split(',')

          // Turn string into object
          row.forEach(item => {
            const kv = item.split('^')
            const k = kv[0]; const v = kv[1]
            this.routeReplace[k] = v
          })

          // get campaign name
          const res = await CampaignController.getCampaign(this.routeReplace.cid)
          this.campaignName = res.data.name
        }
      },

      async sanitizeBreadCrumb() {
        // From screen specs
        if(this.routeFrom === 'artwork') {
          this.breadcrumbItems.push({ text: 'Dashboard', disabled: false, href: '/#/dashboard/campaigns' })
          this.breadcrumbItems.push({ text: this.campaignName, disabled: false, href: '/#/dashboard/campaigns/campaign?cid=' + this.routeReplace.cid})
          this.breadcrumbItems.push({ text: this.routeReplace.bName, disabled: false, href: '/#/dashboard/campaigns/campaign/creatives?cbsid=' + this.routeReplace.cbsid + '&bid=' + this.routeReplace.bid + '&cid=' + this.routeReplace.cid })
        }
        
        else {
          this.breadcrumbItems.push({ text: 'Screens', disabled: false, href: '/#/dashboard/formats' })
          if(!this.isEdit)
            this.breadcrumbItems.push({ text: 'Add Screen', disabled: true})
        }

        this.plasterBreadCrumb(this.name)
      },

      plasterBreadCrumb(screenName) {
        if(this.routeFrom === 'artwork') {
          this.breadcrumbItems.push({ text: screenName, disabled: false, href: '/#/dashboard/campaigns/campaign/creatives/artwork?sid=' + this.routeReplace.sid + '&cbsid=' + this.routeReplace.cbsid + '&bid=' + this.routeReplace.bid + '&cid=' + this.routeReplace.cid + '&bName=' + this.routeReplace.bName + '&tab=' + this.routeReplace.tab})
          this.breadcrumbItems.push({ text: 'Edit', disabled: true})
        }

        else {
          this.breadcrumbItems.push({text: screenName, disabled: true})
        }
      },

      async deleteFormat () {
        await ScreenController.deleteScreenById(this.screen.screen.id).then(() => {
          // Delete format from screen list
          this.formats = this.formats.filter(x => x.id !== this.screen.screen.id)
          this.$nextTick()
          this.$root.$emit('snackbarSuccess', 'Screen deleted successfully.')
        }).catch((err) => {
          this.$root.$emit('snackbarError', ''+ err.response.data.message)
        }).finally(() => {
          // close dialog
          this.deleteFormatDialog = false
          // return back to formats
          this.isScreenPresent = false
        })
      },

      async getChildScreens () {
        await ScreenController.getChildScreens(this.screen.screen.id).then(childScreensRes => {
          this.screenChildScreens = childScreensRes.data
          this.$nextTick()
        })
      },

      async deleteChildScreen (item) {
        await ScreenController.deleteScreenById(item.id)
          .then((res) => {
            this.$root.$emit('snackbarSuccess','Successfully deleted child screen')
            this.getChildScreens()
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
      },

      async addSupportedMedia (item) {
        await ScreenController.addSupportedMedia({
          screenId: parseInt(this.screen.screen.id),
          mediaTypeId: item.id,
        }).then(() => {
          this.$root.$emit('snackbarSuccess', 'Media Type added successfully.')
          ScreenController.getSupportedMedia(this.screen.screen.id)
            .then((res) => {
              this.supportedMediaData = res.data
              this.selectedMediaType = null
            })
            .catch((err) => {
              this.$root.$emit('snackbarError', ''+ err.response.data.message)
            })
        })
      },

      removeSupportedMedia (item) {
        ScreenController.removeSupportedMedia({
          screenId: parseInt(this.screen.screen.id),
          mediaTypeId: parseInt(item.id),
        })
          .then((res) => {
            this.$root.$emit(
              'snackbarSuccess',
              'Supported media deleted successfully.',
            )

            ScreenController.getSupportedMedia(this.screen.screen.id)
              .then((res) => {
                this.supportedMediaData = res.data
              })
              .catch((err) => {
                this.$root.$emit('snackbarError', err)
              })
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', err)
          })
      },

      async affectedScreen (editMode) {
        this.editAction = editMode
        if(this.editAction === 'delete')
          this.deleteFormatDialog = false
        ScreenController.getScreenUsage(this.screen.screen.id)
          .then((res) => {
            this.affectedCampaignBurstScreens = res.data
            
            // are there any affected screens?
            if(this.affectedCampaignBurstScreens.length > 0) {
              this.showAffectedScreenDialog = true
            }
            else {
              this.showAffectedScreenDialog = false
              this.decideEditAction()
            }
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
      },

      decideEditAction()
      {
        if(this.editAction === 'edit')
          this.editScreen()
        else if(this.editAction === 'delete')
          this.deleteFormat()
        this.editAction = null
      },

      async editScreen () {
        this.$refs.form.validate()
        if (this.valid === true) {
          this.showAffectedScreenDialog = false
          await ScreenController.editScreen(
            {
              id: parseInt(this.screen.screen.id),
              screenGroupId: this.screenGroup.id,
              name: this.name,
              mediaOwner: this.selectedMO,
              category: this.category,
              offsetX: parseInt(this.offsetX),
              offsetY: parseInt(this.offsetY),
              width: parseInt(this.width),
              height: parseInt(this.height),
              rotation: parseInt(this.selectedRotation),
              slotDurationSeconds: parseInt(this.slotDurationSeconds),
              slotBreakSeconds: parseInt(this.slotBreakSeconds),
              maxFileSizeKB: parseInt(this.maxFileSize),
              motionSupport: this.selectedMotion,
              brandingBadgeScreenId:
                this.addedBrandingBadgeScreens.length > 0
                  ? this.addedBrandingBadgeScreens[0].id
                  : 0,
              parentScreenId:
                this.addedParentScreen.length > 0
                  ? this.addedParentScreen[0].id
                  : 0,
              filenameConventionPrefix: this.fileNamePrefix,
              webLink: this.webLink,
              dynamicScreen: {
                publishType: this.publishType,
                machineType: this.machineType,
                operatingSystem: this.operatingSystem,
                playerSoftware: this.playerSoftware,
                syncFrequencySeconds: parseInt(this.syncFrequencySeconds),
                isResponsive: this.isResponsive,
              },
              screenEvents: this.screenEvents
            }
          ).then(res => {
            this.isEdit = true
            this.isScreenPresent = true
            const screenIndex = this.screenGroup.screens.findIndex(x => x.deliveryType.id === this.activeDeliveryType.deliveryType.id)
            this.screenGroup.screens[screenIndex].screen = res.data
             this.$nextTick()
            this.$root.$emit('snackbarSuccess', 'Screen edited successfully.')
            // Redirect if coming from Artwork component
            if(this.routeFrom)
              this.$router.push({ name: 'Artwork', query: { ...this.routeReplace } })
          }).catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
        }
      },
      addScreen () {
        this.$refs.form.validate()
        if (this.valid === true) {
          ScreenController.addScreen({
            screenGroupId: this.isEdit ? this.screenGroup.id : 0,
            deliveryTypeId: this.isEdit ? this.activeDeliveryType.deliveryType.id : this.activeDeliveryType.id,
            name: this.name,
            mediaOwner: this.selectedMO,
            category: this.category,
            offsetX: parseInt(this.offsetX),
            offsetY: parseInt(this.offsetY),
            width: parseInt(this.width),
            height: parseInt(this.height),
            rotation: parseInt(this.selectedRotation),
            slotDurationSeconds: parseInt(this.slotDurationSeconds),
            slotBreakSeconds: parseInt(this.slotBreakSeconds),
            maxFileSizeKB: parseInt(this.maxFileSize),
            motionSupport: this.selectedMotion,
            webLink: this.webLink,
            filenameConventionPrefix: this.filenameConventionPrefix,
            brandingBadgeScreenId:
              this.addedBrandingBadgeScreens.length > 0
                ? this.addedBrandingBadgeScreens[0].id
                : 0,
            dynamicScreen: {
              publishType: this.publishType,
              machineType: this.machineType,
              operatingSystem: this.operatingSystem,
              playerSoftware: this.playerSoftware,
              syncFrequencySeconds: this.syncFrequencySeconds,
              isResponsive: this.isResponsive,
            },
          })
            .then(async (res) => {
              this.$root.$emit('snackbarSuccess', 'Screen added successfully.')
              this.isScreenPresent = true
              // Find extry in res.data
              this.screenGroup = res.data
              if(!this.isEdit){
                const firstActive = this.screenGroup.screens.find(x => x.screen != null)
                this.screenByDeliveryType(firstActive)
                this.$router.push({ name: 'EditFormat', query: { id: res.data.id } }).catch(()=>{})
                this.breadcrumbItems = []
                this.isEdit = true
                await this.sanitizeBreadCrumb()
              }
              this.dataLoaded = true
              this.$nextTick()
            })
            .catch((err) => {
              this.$root.$emit('snackbarError', ''+ err.response.data.message)
            })
        }
      },
    },
  }
</script>
